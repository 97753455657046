import Vue from "vue";

export default {
  state: {
    session: {},
    participants: {},
    participantIds: []
  },
  mutations: {
    UPDATE_SESSION(state, payload) {
      state.session = payload;
    },
    SET_CURRENT_EVENT(state) {
      state.participantIds = [];
      state.participants = {};
    },
    SET_PARTICIPANTS(state, payload) {
      state.participantIds = Object.keys(payload);
      state.participants = payload;
    },
    ADD_PARTICIPANT(state, payload) {
      Vue.set(state.participants, payload.id, payload);
      if (state.participantIds.indexOf(payload.id) === -1) {
        state.participantIds = [...state.participantIds, payload.id];
      }
    },
    REMOVE_PARTICIPANT(state, payload) {
      Vue.delete(state.participants, payload.id);
      state.participantIds = state.participantIds.filter(id => {
        return id !== payload.id;
      });
    },
    UPDATE_PARTICIPANT(state, payload) {
      state.participants[payload.id] = payload;
    }
  },
  actions: {
    updateSession({ commit }, payload) {
      commit("UPDATE_SESSION", payload);
    },
    setParticipants({ commit }, payload) {
      commit("SET_PARTICIPANTS", payload);
    },
    addParticipant({ commit }, payload) {
      commit("ADD_PARTICIPANT", payload);
    },
    removeParticipant({ commit }, payload) {
      commit("REMOVE_PARTICIPANT", payload);
    },
    updateParticipant({ commit }, payload) {
      commit("UPDATE_PARTICIPANT", payload);
    }
  },
  getters: {
    countdownTime: state => state.session.countdown || 0,
    sessionState: state => state.session.state,
    raisedHands: state => state.session.raisedHands || {},
    currentSpeaker: state => state.session.speaking,
    sessionPrompts: state => state.session.prompts || {},
    currentPromptIdx: state => state.session.currentPrompt,
    currentPrompt: (state, { prompts, currentPromptIdx }) => {
      return prompts[currentPromptIdx] || {};
    },
    participants: state =>
      state.participantIds.map(uid =>
        Object.assign(state.participants[uid], { uid })
      ),
    participantsById: state => state.participants
  }
};
