<template>
  <HomeBoard :nodes="nodes" />
</template>

<script>
import {
  forceSimulation,
  forceX,
  forceY,
  forceCollide,
  forceManyBody
} from "d3-force";
import { scaleLinear } from "d3-scale";
import { round } from "../utils";
import HomeBoard from "./HomeBoard";

export default {
  props: {
    N: {
      type: Number,
      default: 50
    }
  },
  components: {
    HomeBoard
  },
  data() {
    return {
      nodes: []
    };
  },
  created() {
    this.nodes = [...Array(this.N + 1)].map(i => {
      return {
        normalizedX: Math.random(),
        normalizedY: Math.random(),
        targetX: 0,
        targetY: 0,
        radius: i == 0 ? 0 : 30
      };
    });

    this.simulation = forceSimulation().alpha(0.1);

    this.xScale = scaleLinear().domain([0, 1]);
    this.yScale = scaleLinear().domain([0, 1]);

    this.simulation
      .force("x", forceX(node => node.targetX).strength(0.3))
      .force("y", forceY(node => node.targetY).strength(0.3))
      .force("collide", forceCollide(node => 1.1 * node.radius).strength(0.8))
      .force(
        "charge",
        forceManyBody()
          .strength((d, i) => {
            return i == 0 ? +7000 : 0;
          })
          .distanceMin(200)
      );
  },
  mounted() {
    window.addEventListener("resize", this.rescale);
    window.addEventListener("mousemove", this.update);
    window.addEventListener("touchmove", this.updateTouch);
    this.rescale();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.rescale);
    window.removeEventListener("mousemove", this.update);
    window.removeEventListener("touchmove", this.updateTouch);
  },
  methods: {
    rescale() {
      this.bounds = this.$el.getBoundingClientRect();
      this.xScale.range([0, this.bounds.width]);
      this.yScale.range([0, this.bounds.height]);
      this.updateSimulation();
    },
    update(event) {
      const rect = this.$el.getBoundingClientRect();
      const x = round((event.clientX - rect.left) / rect.width, 4);
      const y = round((event.clientY - rect.top) / rect.height, 4);
      this.nodes[0].fx = this.xScale(x);
      this.nodes[0].fy = this.yScale(y);
      this.simulation.nodes(this.nodes);
      this.simulation.alpha(0.05).restart();
    },
    updateTouch(event) {
      const simulatedMouseEvent = {
        clientX: event.touches[0].clientX,
        clientY: event.touches[0].clientY
      };
      this.update(simulatedMouseEvent);
    },
    updateSimulation() {
      const { width, height } = this.bounds,
        area = width * height,
        nParticipants = Math.max(this.nodes.length, 1),
        maxSize = Math.sqrt(area / (8 * nParticipants)),
        nodeRadius = Math.floor(
          Math.min(maxSize, Math.min(width / 10, height / 4)) / 2
        );

      this.nodes = this.nodes.map((node, i) => {
        node.targetX = this.xScale(node.normalizedX);
        node.targetY = this.yScale(node.normalizedY);
        node.radius = i == 0 ? 0 : nodeRadius;
        return node;
      });

      this.simulation.nodes(this.nodes);
    }
  }
};
</script>
