<template>
  <div class="file-upload">
    <label for="file-upload">
      <input
        type="file"
        ref="file"
        id="file-upload"
        accept="image/*"
        @change="updateImage"
      />
      <slot />
    </label>
  </div>
</template>
<style lang="scss">
@import "../styles/variables";

.file-upload {
  position: relative;
  color: $copy;

  input[type="file"] {
    display: none;
  }

  label {
    display: inline-flex;
    flex-direction: column;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
<script>
export default {
  props: ["validate"],
  methods: {
    open() {
      this.$refs.file.click();
    },
    updateImage(event) {
      const input = event.target;
      if (input.files && input.files[0]) {
        const imageFile = input.files[0];
        if (this.validate instanceof Function) {
          try {
            this.validate(imageFile);
          } catch (err) {
            input.value = null;
            throw err;
          }
        }
        if (this.previewImage) {
          URL.revokeObjectURL(this.previewImage);
        }
        this.previewImage = URL.createObjectURL(imageFile);
        this.$emit("change", {
          file: imageFile,
          preview: this.previewImage
        });
      }
    }
  }
};
</script>
