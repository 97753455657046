import { signOut } from "../../api/auth";
import { getUserData, updateUserProfile } from "../../api/user";

export default {
  state: {
    isLoading: true,
    user: null
  },
  mutations: {
    SET_AUTH_LOADING(state, loading) {
      state.isLoading = loading;
    },
    SET_USER(state, user) {
      state.user = user;
    }
  },
  actions: {
    setAuthLoading({ commit }, payload) {
      commit("SET_AUTH_LOADING", payload);
    },
    async setUser({ commit }, user) {
      const userData = await getUserData(user);
      commit("SET_USER", userData);
      return userData;
    },
    async updateUser({ commit }, payload) {
      const user = await updateUserProfile(payload);
      const userData = await getUserData(user);
      commit("SET_USER", userData);
      return userData;
    },
    async signOut({ commit }) {
      const res = await signOut();
      commit("SET_USER", null);
      return res;
    }
  },
  getters: {
    authLoading: state => state.isLoading,
    user: state => state.user
  }
};
