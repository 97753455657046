const ERROR_CODES = {
  "auth/user-not-found": {
    title: "We are unable to find that user's email.",
    text:
      "Users must create an account before being invited as a collaborator.\
      Please confirm that this person has an account."
  }
};

export function getErrorMessage(err) {
  return (
    ERROR_CODES[err.code] || {
      title: "Problem with request",
      text: err.message
    }
  );
}
