import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";

import { firebaseConfig } from "../config";

export { firebase };
export const app = firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const db = firebase.database();
export const storage = firebase.storage();

export default app;
