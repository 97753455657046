<template>
  <div class="event-container" :class="sessionState">
    <prompt :currentPrompt="currentPrompt" :prompts="prompts">
      <router-link
        v-if="viewOnly"
        class="btn secondary pull-right"
        :to="eventLink"
      >
        Participate
      </router-link>
      <button
        v-else
        class="secondary pull-right"
        @click="setHandRaised(!isHandRaised)"
      >
        <span v-if="isHandRaised">Lower Hand</span>
        <span v-else>Raise Hand</span>
      </button>
    </prompt>
    <tensions
      :currentPrompt="currentPrompt"
      viewerType="participant"
    ></tensions>
    <simulation
      :participants="activeParticipants"
      :speaker="currentSpeaker"
      :raisedHands="raisedHands"
      :user="user"
      @click.prevent="updatePosition"
    >
      <countdown :time="countdownTime"></countdown>
    </simulation>
    <div class="action-container wrapper">
      <router-link
        v-if="viewOnly"
        class="btn secondary pull-right"
        :to="eventLink"
      >
        Participate
      </router-link>
      <button
        v-else
        class="secondary pull-right"
        @click="setHandRaised(!isHandRaised)"
      >
        <span v-if="isHandRaised">Lower Hand</span>
        <span v-else>Raise Hand</span>
      </button>
    </div>
    <overlay
      :state="sessionState"
      :event="event"
      :participants="participants"
    ></overlay>
    <blocker>Please rotate your device to landscape mode</blocker>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Session, { PROMPT, EVENT } from "../api/session";
import Simulation from "../components/Simulation";
import Overlay from "../components/Overlay";
import Countdown from "../components/Countdown";
import Prompt from "../components/Prompt";
import Tensions from "../components/Tensions";
import Blocker from "../components/Blocker";
import { round } from "../utils";

export default {
  components: {
    Simulation,
    Prompt,
    Tensions,
    Blocker,
    Overlay,
    Countdown
  },
  props: ["viewOnly"],
  computed: {
    ...mapGetters([
      "user",
      "event",
      "prompts",
      "participants",
      "countdownTime",
      "currentPrompt",
      "currentSpeaker",
      "raisedHands",
      "sessionState"
    ]),
    eventLink() {
      return `/${this.event.id}`;
    },
    isHandRaised() {
      return this.raisedHands[this.user.uid];
    },
    activeParticipants() {
      if (this.sessionState === EVENT.INITIAL) {
        return this.participants;
      }

      switch (this.currentPrompt.state) {
        case PROMPT.NOT_STARTED:
        case PROMPT.SHOW_INDIVIDUAL:
          return this.participants.filter(p => p.uid === this.user.uid);
        case PROMPT.SHOW_ALL:
        case PROMPT.CLOSED:
        default:
          return this.participants;
      }
    },
    clicksEnabled() {
      if (
        this.sessionState === EVENT.FINISHED ||
        this.sessionState === EVENT.INITIAL
      ) {
        return true;
      }
      switch (this.currentPrompt.state) {
        case PROMPT.NOT_STARTED:
        case PROMPT.SHOW_INDIVIDUAL:
        case PROMPT.SHOW_ALL:
          return true;
        default:
          return false;
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const { eventId } = to.params;
      vm.setSession(new Session(eventId));
    });
  },
  async mounted() {
    if (window.screen && window.screen.orientation) {
      screen.orientation.addEventListener("change", this.updateOrientation);
    }
  },
  async beforeDestroy() {
    if (this.session) {
      await this.session.leave();
    }
    if (window.screen && window.screen.orientation) {
      screen.orientation.removeEventListener("change", this.updateOrientation);
    }
  },
  methods: {
    ...mapActions([
      "updateSession",
      "addParticipant",
      "updateParticipant",
      "removeParticipant"
    ]),
    async setSession(session) {
      if (this.session) {
        await this.session.leave();
      }

      this.session = session;

      this.session.onParticipantAdded(this.addParticipant);
      this.session.onParticipantRemoved(this.removeParticipant);
      this.session.onParticipantChanged(this.updateParticipant);
      this.session.onSessionChanged(this.updateSession);

      if (!this.viewOnly) {
        this.session.join(this.user);
      }
    },
    setHandRaised(value) {
      this.session.setHandRaised(this.user.uid, value);
    },
    updateOrientation() {
      if (screen.orientation.type.includes("landscape")) {
        this.$el.requestFullscreen().catch();
      } else {
        document.exitFullscreen();
      }
    },
    updatePosition(event) {
      if (this.clicksEnabled && !this.viewOnly) {
        const rect = event.currentTarget.getBoundingClientRect();
        const x = round((event.clientX - rect.left) / rect.width, 4);
        const y = round((event.clientY - rect.top) / rect.height, 4);
        this.session.updatePosition({ x, y });
      }
    }
  }
};
</script>
