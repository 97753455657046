export const authConfig = {
  connection: "google-oauth2",
  allowedDomains: ["ideo.com", "ideo.org"]
  // redirectUri: `${window.location.origin}`
};

const projectId = process.env.VUE_APP_FIREBASE_PROJECT_ID;
const authDomain =
  process.env.VUE_APP_FIREBASE_AUTH_DOMAIN || window.location.host;
export const firebaseConfig = {
  projectId,
  authDomain,
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  databaseURL: `https://${projectId}.firebaseio.com`,
  storageBucket: `${projectId}.appspot.com`,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID
};

export default {
  authConfig,
  firebaseConfig
};
