<template>
  <div class="app-modal">
    <p>
      <strong>
        {{ title }}
      </strong>
    </p>
    <p v-if="htmlText" v-html="htmlText"></p>
    <p v-else>
      {{ text }}
    </p>
    <div v-if="buttons" class="actions">
      <button
        v-for="btn in buttons"
        :key="btn.title"
        :class="btn.class"
        @click="handleClick(btn)"
      >
        {{ btn.title }}
      </button>
    </div>
    <div v-else class="actions">
      <button @click="$emit('close')">
        Close
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "app-modal",
  props: ["title", "text", "htmlText", "buttons"],
  methods: {
    handleClick(btn) {
      if (btn.handler) {
        btn.handler(this);
      } else {
        this.$emit("close");
      }
    }
  }
};
</script>
