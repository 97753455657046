<template>
  <div class="finished">
    <div class="wrapper">
      <p class="intro">
        <strong>Thank you</strong> for participating!<br />
        We hope you enjoyed <em>Creative Tensions.</em>
      </p>
      <p>
        <em>
          Please send your thoughts about this experience to
          <a
            href="mailto:creative-tensions-app@ideo.com"
            title="Creative Tensions App email"
          >
            creative-tensions-app@ideo.com
          </a>
        </em>
      </p>
      <p class="instruction">&#9825; The Digital Creative Tensions Team</p>
    </div>
  </div>
</template>
<script>
export default {
  props: ["event", "participants"]
};
</script>
