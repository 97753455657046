import Vue from "vue";
import { enableAnalytics } from "../../api/analytics";

const COOKIE_KEY = "cookie:accepted";
const USER_PREFS_KEY = "ct:userprefs";

function getItem(key) {
  const itemStr = localStorage.getItem(key);
  return itemStr ? JSON.parse(itemStr) : null;
}

function setItem(key, value) {
  const itemStr = JSON.stringify(value);
  localStorage.setItem(key, itemStr);
}

function updateUserPrefs(payload) {
  const currentPrefs = getItem(USER_PREFS_KEY) || {};
  const newPrefs = Object.assign({}, currentPrefs, payload);
  setItem(USER_PREFS_KEY, newPrefs);
  return newPrefs;
}

export default {
  state: {
    analyticsEnabled: getItem(COOKIE_KEY),
    ...getItem(USER_PREFS_KEY)
  },
  mutations: {
    UPDATE_PREFS(state, payload) {
      Object.entries(payload).forEach(([key, value]) => {
        Vue.set(state, key, value);
      });
    }
  },
  actions: {
    enableAnalytics({ commit }, payload) {
      enableAnalytics(payload);
      setItem(COOKIE_KEY, payload);
      commit("UPDATE_PREFS", { analyticsEnabled: payload });
    },
    updateUserPrefs({ commit }, payload) {
      const newPrefs = updateUserPrefs(payload);
      commit("UPDATE_PREFS", newPrefs);
    }
  },
  getters: {
    userPrefs: state => state
  }
};
