export default class ParticipantNode {
  constructor({ uid, x, y }) {
    this.id = uid;
    this.x = this.targetX = x;
    this.y = this.targetY = y;

    // this.stepProgress = Math.random();
    // this.stepMaxLift = Math.random();
    // this.stepTime = Math.random();
  }

  setName(name) {
    if (this.name !== name) {
      this.name = name;
      this.firstName = name.split(" ")[0];
    }
  }

  setBounds(bounds, radius) {
    this.radius = radius;
    this.maxX = bounds.width - this.radius;
    this.maxY = bounds.height - this.radius;
  }

  setTarget(x, y) {
    this.targetX = x;
    this.targetY = y;
  }

  update() {
    this.x = Math.max(this.radius, Math.min(this.maxX, this.x));
    this.y = Math.max(this.radius, Math.min(this.maxY, this.y));
    // BOUNCY CODE!
    // const dist = Math.abs(this.targetX - this.x);
    // this.stepProgress += 0.0167 / this.stepTime;
    // if (this.stepProgress == 1) {
    //   this.stepProgress = 0;
    // }
    // this.y += Math.sin(this.stepProgress * Math.PI) * this.stepMaxLift;
  }
}
