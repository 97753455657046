import { db, firebase } from "./firebase";
import { createPrompt } from "./prompts";
const { EVENT_ROLES } = require("../../functions/lib/constants");
import apiClient from "./client";

export const getEvent = eventId => {
  return db
    .ref(`events/${eventId}`)
    .once("value")
    .then(snap => {
      const event = snap.val();
      return event && Object.assign(event, { id: snap.key });
    });
};

export const getUserEvents = userId => {
  return db
    .ref(`eventsByUser/${userId}`)
    .once("value")
    .then(snap => snap.val());
};

export const getUserEventDetails = async userId => {
  const userEvents = await getUserEvents(userId);
  const eventIds = Object.keys(userEvents || {});
  const promises = eventIds.map(eventId => getEvent(eventId));
  return Promise.all(promises);
};

export const getEventPrompts = eventId => {
  return db
    .ref(`prompts/${eventId}`)
    .once("value")
    .then(snap => snap.val());
};

export const createEvent = async uid => {
  if (!uid) throw new Error("Must provide a user ID");

  const eventRef = db.ref(`events`).push();
  const eventId = eventRef.key;
  const snap = await eventRef.once("value");

  if (snap.exists()) {
    throw new Error("Event name already exists");
  }

  const event = {
    createdAt: firebase.database.ServerValue.TIMESTAMP,
    updatedAt: firebase.database.ServerValue.TIMESTAMP,
    users: {
      [uid]: EVENT_ROLES.OWNER
    },
    state: "initial"
  };

  const updates = {
    [`events/${eventId}`]: event,
    [`eventsByUser/${uid}/${eventId}`]: EVENT_ROLES.OWNER
  };

  return db
    .ref()
    .update(updates)
    .then(() => createPrompt({ eventId })) // create initial empty prompt
    .then(() => Object.assign(event, { id: eventId }));
};

export const deleteEvent = (uid, eventId) => {
  const updates = {
    [`events/${eventId}`]: null,
    [`eventsByUser/${uid}/${eventId}`]: null
  };

  return db.ref().update(updates);
};

export const updateEvent = event => {
  const eventRef = db.ref(`events/${event.id}`);
  event.updatedAt = firebase.database.ServerValue.TIMESTAMP;

  return eventRef.update(event).then(() => event);
};

export async function getEventUsers(eventId) {
  return apiClient.get(`/events/${eventId}/users`).then(res => res.data.users);
}

export async function updateEventUser({ eventId, email, uid, role }) {
  return apiClient
    .post(`/events/${eventId}/users`, { email, uid, role })
    .then(res => res.data);
}

export async function removeEventUser({ eventId, uid }) {
  return apiClient
    .delete(`/events/${eventId}/users/${uid}`)
    .then(res => res.data);
}

export function hasEventRole(user, event, roles) {
  const eventRole = event && event.users[user.uid];
  return roles.indexOf(eventRole) !== -1;
}
