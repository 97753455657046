<template>
  <div class="home">
    <div class="fixed-background">
      <HomeSimulation />
    </div>
    <div class="fixed-background vignette" />
    <div class="wrapper content">
      <div class="row bottom-xs">
        <div class="col-lg-6 col-xs-12">
          <intro-language></intro-language>
        </div>
        <div class="col-lg-5 col-lg-offset-1 col-xs-12">
          <login v-if="!user" />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import "../styles/variables";
@import "../styles/mixins";

.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include full-height;
  text-align: center;

  .fixed-background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .wrapper {
    position: relative;
  }

  .vignette {
    background-image: radial-gradient(
      ellipse closest-side,
      rgba(0, 0, 0, 0.8) 50%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  @media only screen and (max-width: 820px) {
    .content {
      padding-top: 2em;
    }
  }
}
</style>
<script>
import { mapGetters } from "vuex";
import Login from "../components/app/Login";
import IntroLanguage from "../components/app/IntroLanguage";
import HomeSimulation from "../components/HomeSimulation";

export default {
  components: {
    Login,
    IntroLanguage,
    HomeSimulation
  },
  computed: {
    ...mapGetters(["user"])
  }
};
</script>
