<template>
  <nav v-if="user" id="nav" class="wrapper">
    <router-link to="/" class="ct-logo" title="Go home"></router-link>
    <router-link
      v-if="!user.isAnonymous"
      to="/events"
      aria-label="List of your events"
    >
      <h4>Events</h4>
    </router-link>
    <a
      @click.prevent="handleSignOut()"
      href="#"
      aria-label="Sign out of Creative Tensions"
    >
      <h4>Sign out</h4>
    </a>
    <router-link to="/profile" aria-label="Edit your profile">
      <img
        class="profile-img"
        referrerpolicy="no-referrer"
        :src="profileImage"
      />
    </router-link>
  </nav>
</template>

<style lang="scss">
@import "../../styles/variables";

.ct-logo {
  background: url(../../assets/logo.svg) no-repeat center;
  height: 54px;
  width: 150px;
  margin-left: 0;
  margin-right: auto;
  background-size: contain;
  border: none;
  flex-grow: 0;

  &:hover {
    border: none;
  }
}

nav,
footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 1;

  a {
    color: $primary;
    text-decoration: none;
    margin-left: 40px;
    h4 {
      margin: 0;
      border-bottom: 2px solid transparent;
    }

    &.router-link-active,
    &:hover {
      h4 {
        border-bottom: 2px solid $primary;
      }

      .profile-img {
        border: 3px solid $primary;
      }
    }
  }

  .profile-img {
    height: 54px;
    width: 54px;
    border-radius: 50%;
    display: block;
    object-fit: cover;
    box-sizing: border-box;
    border: 3px solid transparent;
  }
}
</style>

<script>
import { mapActions } from "vuex";
import defaultAvatar from "../../assets/default-avatar.svg";

export default {
  props: ["user"],
  computed: {
    profileImage() {
      return this.user.picture || defaultAvatar;
    }
  },
  methods: {
    ...mapActions(["signOut"]),
    async handleSignOut() {
      let promise = Promise.resolve();
      if (this.$route.path !== "/") {
        promise = this.$router.push("/");
      }
      await promise.then(() => this.signOut());
    }
  }
};
</script>
