import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth";
import session from "./session";
import events from "./events";
import prompts from "./prompts";
import prefs from "./prefs";
import { analyticsPlugin } from "../api/analytics";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  plugins: [analyticsPlugin],
  modules: {
    auth,
    session,
    events,
    prompts,
    prefs
  }
});

export default store;

if (module.hot) {
  module.hot.accept(["./auth", "./session", "./events", "./prompts"], () => {
    const auth = require("./auth").default;
    const session = require("./session").default;
    const events = require("./events").default;
    const prompts = require("./prompts").default;

    store.hotUpdate({
      modules: {
        auth,
        session,
        events,
        prompts
      }
    });
  });
}
