import axios from "axios";
import { getIdToken } from "./auth";

const client = axios.create({ baseURL: "/api" });

client.interceptors.request.use(async req => {
  const token = await getIdToken();
  req.headers["Authorization"] = `Bearer ${token}`;
  return req;
});

client.interceptors.response.use(
  res => res,
  err => {
    if (err.response.data && err.response.data.message) {
      err.message = err.response.data.message;
      err.code = err.response.data.code;
    }
    return Promise.reject(err);
  }
);

export default client;
