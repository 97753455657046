<template>
  <div for="avatar-configurer" class="avatar-configurer">
    <input
      class="rainbow"
      type="range"
      id="color"
      name="color"
      min="0"
      max="360"
      @input="changeColor($event.target.value)"
      :value="sliderValue"
      :style="sliderStyle"
    />
  </div>
</template>
<style lang="scss">
@import "../styles/variables";
input[type="file"] {
  display: none;
}
.avatar-configurer {
  margin: 0 auto;
  color: $copy;
  display: flex;
  flex-direction: column;
  align-items: center;
  .avatar-preview {
    width: 250px;
    height: 250px;
    background-size: cover;
    background-position: center;
    margin-bottom: 30px;
  }
}
</style>
<script>
import {
  isDataURI,
  getFillFromDataURI,
  getPictureCSS,
  getPictureDataURI
} from "../utils";
import chroma from "../utils/chroma";

export default {
  props: {
    value: {
      type: String
    }
  },
  data() {
    return {
      sliderValue: 0,
      icon: "default"
    };
  },
  mounted() {
    // Get current fill color from svg
    if (isDataURI(this.value)) {
      const fill = getFillFromDataURI(this.value);
      this.sliderValue = chroma(fill).hcl()[0];
    } else {
      this.changeColor(this.sliderValue);
    }
  },
  computed: {
    avatarStyle() {
      return getPictureCSS(this.value);
    },
    color() {
      return this.getColor(this.sliderValue);
    },
    sliderStyle() {
      return {
        ["--thumbcolor"]: this.color
      };
    }
  },
  methods: {
    getColor(deg) {
      return chroma.hcl(deg, 80, 60).hex();
    },
    changeColor(value) {
      this.sliderValue = value;
      this.$emit("change", getPictureDataURI(this.icon, this.color));
    },
    printGradient() {
      // to feed to the scss file
      const stride = 360 / 50;
      let at = 0;
      let colors = [];
      while (at < 360) {
        colors.push(this.getColor(at));
        at += stride;
      }
    }
  }
};
</script>
