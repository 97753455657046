<template>
  <div class="interstitial overlay-gradient">
    <component :is="interstitial" />
  </div>
</template>

<style lang="scss">
.overlay-container {
  .interstitial {
    .title,
    .subtitle {
      max-width: 700px;
    }
    .title {
      font-size: 7rem;
      margin: 2rem 0;
    }
    .subtitle {
      font-size: 1.5rem;
    }
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) {
      .title {
        font-size: 4rem;
        margin-top: 0;
      }
      .subtitle {
        font-size: 1.5rem;
      }
    }
  }
}
.moderate {
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) {
    .action-container {
      display: flex;
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import { PROMPT_TYPES } from "../api/prompts";
import WarmUp from "./interstitials/WarmUp";
import RealTalk from "./interstitials/RealTalk";
import Lightning from "./interstitials/Lightning";

export default {
  computed: {
    ...mapGetters(["currentPrompt"]),
    interstitial() {
      const componentMap = {
        [PROMPT_TYPES.WARM_UP]: WarmUp,
        [PROMPT_TYPES.REAL_TALK]: RealTalk,
        [PROMPT_TYPES.LIGHTNING]: Lightning
      };
      return componentMap[this.currentPrompt.type];
    }
  }
};
</script>
