import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vSelect from "vue-select";
import VModal from "vue-js-modal";

Vue.use(VModal, {
  dialog: true,
  dynamicDefaults: {
    adaptive: true,
    height: "auto"
  }
});

Vue.component("v-select", vSelect);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
