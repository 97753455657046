<template>
  <footer id="footer">
    <div class="wrapper">
      <div class="row center-xs">
        <main-logo
          class="col-md-2 col-xs-12 start-md center-xs first-md last-xs"
        />
        <div class="col-md-10 col-xs-12 end-md center-xs">
          <a
            href="https://docs.google.com/presentation/d/1CAeA4dEmXio5l8uZaash450T6ZxOFVrkMYe0D6NQV8g"
            target="_blank"
          >
            <h4>Guide + FAQ</h4>
          </a>
          <a href="#" @click.prevent="showTerms()">
            <h4>Terms</h4>
          </a>
          <a href="https://www.ideo.com/privacy" target="_blank">
            <h4>Privacy Policy</h4>
          </a>
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSd-VDt-8kvGYbCfKO-0jfDpzdak8u8kyCTv3Ke_XJikH5tDcQ/viewform"
            target="_blank"
          >
            <h4>Do Not Sell My Personal Information</h4>
          </a>
          <a href="https://www.ideo.com/privacy#privacy-rights" target="_blank">
            <h4>CA User Rights</h4>
          </a>
          <a href="mailto:creative-tensions-app@ideo.com">
            <h4>Contact</h4>
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>
<style lang="scss">
@import "../../styles/variables";

#footer {
  position: relative;
  margin-top: auto;
  margin-bottom: 0;
  padding: 0;
  .wrapper {
    border-top: 1px solid $line-color;
    padding-top: 1em;
    padding-bottom: 1em;
    a {
      margin: 1rem;
      display: inline-block;
      white-space: nowrap;
      font-size: 0.75rem;
    }
  }
}
</style>
<script>
import MainLogo from "./MainLogo";
const TermsModal = () => import(/* webpackChunkName: "terms" */ "./TermsModal");

export default {
  components: {
    MainLogo
  },
  methods: {
    showTerms() {
      this.$modal.show(
        TermsModal,
        {},
        { width: 1300, height: 600, maxHeight: 600, maxWidth: 1300 }
      );
    }
  }
};
</script>
