<template>
  <div class="prompt-container wrapper">
    <transition name="fade" mode="out-in" appear>
      <div class="prompt" :key="currentPrompt.id">
        <label>Prompt {{ currentPrompt.idx + 1 }}/{{ prompts.length }}</label>
        <span v-if="currentPrompt.prompt">{{ currentPrompt.prompt }}...</span>
      </div>
    </transition>
    <div class="prompt-actions">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss">
@import "../styles/variables";
@import "../styles/mixins";

.prompt-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  line-height: 2rem;
  height: calc(4rem + 40px);
  user-select: none;

  .prompt {
    font-size: 1.6rem;
    max-width: 40ch;
    font-weight: 400;

    label {
      @include upper;
      color: $copy;
      font-size: 15px;
      font-style: normal;
    }
  }
}
</style>

<script>
export default {
  props: ["currentPrompt", "prompts"]
};
</script>
