<template>
  <cookie-law
    theme="dct"
    transitionName="fade"
    :buttonDecline="true"
    buttonDeclineClass="secondary"
    buttonClass="primary"
    v-on:accept="enableAnalytics(true)"
    v-on:reject="enableAnalytics(false)"
    buttonText="Accept"
    buttonDeclineText="Decline"
  >
    <div slot="message">
      To help the Creative Tensions App team improve this product, we use
      statistics cookies to capture anonymous usage data. Learn more
      <a
        href="https://www.ideo.com/privacy#personal-information"
        target="_blank"
      >
        here.
      </a>
    </div>
  </cookie-law>
</template>

<style lang="scss">
@import "../../styles/variables";

.Cookie--dct.Cookie--bottom {
  position: sticky;
  bottom: 0;
  z-index: 0;
  background-color: rgba($grey-dark, 0.95);
  padding: 1rem 1.5rem;
  max-width: 700px;
  margin: 0 auto;
  .Cookie__buttons {
    flex-direction: row;
    button {
      margin: 0 0.5rem;
    }
  }
}
</style>
<script>
import CookieLaw from "vue-cookie-law";
import { mapActions } from "vuex";

export default {
  components: {
    CookieLaw
  },
  methods: {
    ...mapActions(["enableAnalytics"])
  }
};
</script>
