export function merge(obj, key, value) {
  return Object.assign(obj || {}, {
    [key]: value
  });
}

export function normalize(arr, key = "id") {
  return arr.reduce((acc, item) => {
    acc[item[key]] = item;
    return acc;
  }, {});
}

export function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w-]+/g, "") // Remove all non-word chars
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
}

export function round(value, decimals) {
  return Number(Math.round(value + `e${decimals}`) + `e-${decimals}`);
}

export function throttle(func, wait, options) {
  var context, args, result;
  var timeout = null;
  var previous = 0;
  if (!options) options = {};
  var later = function() {
    previous = options.leading === false ? 0 : Date.now();
    timeout = null;
    result = func.apply(context, args);
    if (!timeout) context = args = null;
  };
  return function() {
    var now = Date.now();
    if (!previous && options.leading === false) previous = now;
    var remaining = wait - (now - previous);
    context = this;
    args = arguments;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func.apply(context, args);
      if (!timeout) context = args = null;
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };
}

// from https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
export function copyTextToClipboard(text) {
  var textArea = document.createElement("textarea");

  //
  // *** This styling is an extra step which is likely not required. ***
  //
  // Why is it here? To ensure:
  // 1. the element is able to have focus and selection.
  // 2. if element was to flash render it has minimal visual impact.
  // 3. less flakyness with selection and copying which **might** occur if
  //    the textarea element is not visible.
  //
  // The likelihood is the element won't even render, not even a
  // flash, so some of these are just precautions. However in
  // Internet Explorer the element is visible whilst the popup
  // box asking the user for permission for the web page to
  // copy to the clipboard.
  //

  // Place in top-left corner of screen regardless of scroll position.
  textArea.style.position = "fixed";
  textArea.style.top = 0;
  textArea.style.left = 0;

  // Ensure it has a small width and height. Setting to 1px / 1em
  // doesn't work as this gives a negative w/h on some browsers.
  textArea.style.width = "2em";
  textArea.style.height = "2em";

  // We don't need padding, reducing the size if it does flash render.
  textArea.style.padding = 0;

  // Clean up any borders.
  textArea.style.border = "none";
  textArea.style.outline = "none";
  textArea.style.boxShadow = "none";

  // Avoid flash of white box if rendered for any reason.
  textArea.style.background = "transparent";

  textArea.value = text;

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand("copy");
    if (!successful) {
      throw new Error("Error trying to copy");
    }
  } catch (err) {
    throw new Error("Error trying to copy");
  } finally {
    document.body.removeChild(textArea);
  }
}

export function timestamp(additional_seconds = 0) {
  return Date.now() + additional_seconds * 1000;
}

const DATA_IMAGE_HEADER = "data:image/svg+xml;base64,";

export function isDataURI(value) {
  return value && value.startsWith(DATA_IMAGE_HEADER);
}

export function getFillFromDataURI(value) {
  const svg = atob(value.split(",")[1]);
  const div = document.createElement("div");
  div.innerHTML = svg;
  const fill = div.querySelector("#fill-color").getAttribute("fill");
  return fill;
}

export function getPictureDataURI(icon, fill) {
  const library = {
    default: `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle id="fill-color" cx="16" cy="16" r="16" fill="${fill}"/>
    <circle cx="10.3175" cy="11.0675" r="2.31746" fill="black"/>
    <circle cx="21.6388" cy="11.0675" r="2.31746" fill="black"/>
    <path d="M24.6905 18.3096C24.6905 20.6145 23.7749 22.8249 22.1451 24.4547C20.5154 26.0845 18.3049 27.0001 16 27.0001C13.6952 27.0001 11.4847 26.0845 9.85495 24.4547C8.22517 22.8249 7.30957 20.6145 7.30957 18.3096L16 18.3096H24.6905Z" fill="black"/>
    </svg>`
  };
  const svg_data = btoa(library[icon]);
  return DATA_IMAGE_HEADER + svg_data;
}

export function getPictureCSS(picture) {
  return {
    backgroundImage: `url("${picture}")`
  };
}

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
