import { auth, storage } from "./firebase";

function parseName(name) {
  const firstName = name.split(" ")[0];
  const lastName = name.substring(firstName.length).trim();
  return { firstName, lastName };
}

function parsePhoto(url) {
  if (url && url.indexOf("googleusercontent.com") !== -1) {
    url = `${url.split("=")[0]}=s256-c`; // get 256px square crop
  }
  return url;
}

export async function getUserData(user) {
  if (user) {
    const { claims } = await user.getIdTokenResult();
    const { uid, displayName, photoURL, isAnonymous, providerData } = user;
    if (!isAnonymous && !claims.role) {
      return null;
    }
    const { firstName, lastName } = parseName(displayName || "");
    return {
      uid,
      firstName,
      lastName,
      isAnonymous,
      role: claims.role,
      name: displayName || "",
      picture: parsePhoto(photoURL),
      photos: providerData.map(d => parsePhoto(d.photoURL)),
      providerData
    };
  }
  return null;
}

export async function updateUserProfile(user) {
  return auth.currentUser
    .updateProfile({
      displayName: user.name,
      photoURL: user.picture
    })
    .then(() => auth.currentUser);
}

export function uploadProfileImage(imageFile) {
  const { uid } = auth.currentUser;
  const uploadRef = storage.ref(`/users/${uid}/profile.jpg`);
  const metadata = {
    cacheControl: "public,max-age=3600"
  };

  return uploadRef.put(imageFile, metadata).then(snap => {
    return snap.ref.getDownloadURL();
  });
}
