<template>
  <div v-if="participant" class="participant" :style="participantStyle">
    <div class="name">
      {{ participantName }}
    </div>
  </div>
</template>
<style lang="scss">
@import "../styles/variables";

.participant {
  pointer-events: none;
  position: absolute;
  width: 45px;
  height: 45px;
  transform: translate(-50%, -50%);
  color: #fff;
  z-index: 2;

  .name {
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translate(-50%, 100%);
    text-align: center;
    background: $primary;
    color: black;
    font-weight: 400;
    padding: 3px 5px;
    border-radius: 3px;
    z-index: 100;
    min-width: 100%;
    user-select: none;
  }
}
</style>
<script>
export default {
  props: ["speaker", "user", "participant"],
  computed: {
    participantStyle() {
      const participant = this.participant;
      return {
        top: `${Math.round(participant.y)}px`,
        left: `${Math.round(participant.x)}px`,
        width: `${Math.round(participant.radius * 2)}px`,
        height: `${Math.round(participant.radius * 2)}px`
      };
    },
    participantName() {
      const participant = this.participant;
      if (!participant) {
        return "Unnamed participant";
      }
      if (this.speaker === participant.id) {
        if (participant.id === this.user.uid) {
          return "The host has called on you";
        }
        return `${participant.firstName} is speaking`;
      }
      return participant.name;
    }
  }
};
</script>
