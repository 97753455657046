import "firebase/analytics";
import { firebase } from "./firebase";

let analytics = {
  logEvent: () => {},
  setAnalyticsCollectionEnabled: () => {},
  setCurrentScreen: () => {},
  setUserProperties: () => {}
};

if (firebase.analytics) {
  analytics = firebase.analytics();
}

export function logScreenView(to) {
  let extraParams = {
    screen_name: to.name
  };

  const { eventId } = to.params;
  if (eventId) {
    extraParams.event_id = eventId;
  }

  analytics.setCurrentScreen(to.name);
  analytics.logEvent("screen_view", extraParams);
}

function logUser(user) {
  if (user) {
    const { isAnonymous } = user;
    analytics.setUserProperties({ isAnonymous });
  }
}

function logEventCreate(event) {
  analytics.logEvent("create_event", { event_id: event.id });
}

function logEventRemove(eventId) {
  analytics.logEvent("remove_event", { event_id: eventId });
}

export function logEventStart({ event, participants }) {
  analytics.logEvent("event_start", {
    event_id: event.id,
    num_participants: participants.length
  });
}

export function logEventFinish({ event, participants }) {
  analytics.logEvent("event_finish", {
    event_id: event.id,
    num_participants: participants.length
  });
}

export function enableAnalytics(doit) {
  analytics.setAnalyticsCollectionEnabled(doit);
}

export const analyticsPlugin = store => {
  // default to disabling analytics for GDPR
  enableAnalytics(false);

  const handlers = {
    SET_USER: logUser,
    ADD_EVENT: logEventCreate,
    REMOVE_EVENT: logEventRemove
  };

  store.subscribe((mutation, state) => {
    const handler = handlers[mutation.type];
    if (handler) {
      handler(mutation.payload, state, store);
    }
  });
};
