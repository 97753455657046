import {
  createPrompt,
  updatePrompt,
  deletePrompt,
  reorderPrompts
} from "../../api/prompts";
import Vue from "vue";
import { normalize } from "../../utils";

export default {
  state: {
    isLoading: false,
    prompts: {}
  },
  mutations: {
    SET_CURRENT_EVENT(state) {
      state.isLoading = true;
    },
    SET_PROMPTS(state, prompts) {
      state.prompts = prompts;
      state.isLoading = false;
    },
    ADD_PROMPT(state, prompt) {
      Vue.set(state.prompts, prompt.id, prompt);
    },
    UPDATE_PROMPT(state, prompt) {
      Vue.set(state.prompts, prompt.id, {
        ...state.prompts[prompt.id],
        ...prompt
      });
    },
    REORDER_PROMPTS(state, { from, to }) {
      const fromIdx = from.idx;
      const toIdx = to.idx;
      Vue.set(state.prompts[from.id], "idx", toIdx);
      Vue.set(state.prompts[to.id], "idx", fromIdx);
    },
    REMOVE_PROMPT(state, prompt) {
      Vue.delete(state.prompts, prompt.id);
    }
  },
  actions: {
    async createPrompt({ commit, getters }, payload) {
      payload.idx = getters.prompts.length;
      const prompt = await createPrompt(payload);
      commit("ADD_PROMPT", prompt);
      return prompt;
    },
    async updatePrompt({ commit, getters }, payload) {
      const prompt = await updatePrompt({
        eventId: getters.event.id,
        prompt: payload
      });
      commit("UPDATE_PROMPT", prompt);
      return prompt;
    },
    async reorderPrompts({ commit, getters }, { from, to }) {
      commit("REORDER_PROMPTS", { from, to });
      const updatedPrompts = await reorderPrompts({
        eventId: getters.event.id,
        prompts: getters.prompts
      });
      commit("SET_PROMPTS", normalize(updatedPrompts));
      return updatedPrompts;
    },
    async deletePrompt({ commit, getters }, prompt) {
      commit("REMOVE_PROMPT", prompt);
      const updatedPrompts = await deletePrompt({
        eventId: getters.event.id,
        prompt
      });
      commit("SET_PROMPTS", updatedPrompts);
      return updatedPrompts;
    }
  },
  getters: {
    promptsLoading: state => state.isLoading,
    prompts: (state, { sessionPrompts }) => {
      return Object.keys(state.prompts)
        .map((id, idx) => ({
          id,
          idx,
          state: sessionPrompts[id],
          ...state.prompts[id]
        }))
        .sort((a, b) => a.idx - b.idx);
    }
  }
};
