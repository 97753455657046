<template>
  <div class="participant-container" @click="$emit('click', $event)">
    <div class="tension-line" />
    <board
      :nodes="nodes"
      :imageAtlas="imageAtlas"
      :speaker="speaker"
      :raisedHands="raisedHands"
      :user="user"
      @participantClicked="$emit('participantClicked', $event)"
    />
    <slot></slot>
  </div>
</template>

<style lang="scss">
@import "../styles/variables";

.participant-container {
  .tension-line {
    width: 1px;
    background-image: linear-gradient($line-color, rgba($line-color, 0));
    height: 100%;
    pointer-events: none;
    position: absolute;
    left: 50%;
    z-index: 0;
  }
}
</style>

<script>
import { forceSimulation, forceX, forceY, forceCollide } from "d3-force";
import { scaleLinear } from "d3-scale";
import ParticipantNode from "./entities/ParticipantNode";
import Board from "./Board";
import ImageAtlas from "./entities/ImageAtlas";

export default {
  components: {
    Board
  },
  props: ["participants", "speaker", "raisedHands", "user"],
  data() {
    return {
      nodes: [],
      nodesById: new Map(),
      imageAtlas: new ImageAtlas()
    };
  },
  watch: {
    participants(participants) {
      this.updateSimulation(participants);
    }
  },
  created() {
    this.simulation = forceSimulation()
      .alpha(0.1)
      .alphaTarget(0.05);

    this.xScale = scaleLinear().domain([0, 1]);
    this.yScale = scaleLinear().domain([0, 1]);

    this.simulation
      .force("x", forceX(node => node.targetX).strength(0.5))
      .force("y", forceY(node => node.targetY).strength(0.3))
      .force("collide", forceCollide(node => node.radius).strength(0.7))
      .on("tick", () => {
        this.nodes.forEach(node => node.update());
      });
  },
  mounted() {
    window.addEventListener("resize", this.rescale);
    this.rescale();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.rescale);
  },
  methods: {
    rescale() {
      this.bounds = this.$el.getBoundingClientRect();
      this.xScale.range([0, this.bounds.width]);
      this.yScale.range([0, this.bounds.height]);
      this.calculateRadius();
      this.updateSimulation(this.participants);
    },
    calculateRadius() {
      const { width, height } = this.bounds,
        area = width * height,
        nParticipants = Math.max(this.nodes.length, 1),
        maxSize = Math.sqrt(area / (4 * nParticipants)),
        nodeRadius = Math.floor(
          Math.min(maxSize, Math.min(width / 10, height / 4)) / 2
        ),
        selfRadius = Math.floor(nodeRadius * 1.4);

      this.nodes.forEach(node => {
        const radius = node.id === this.user.uid ? selfRadius : nodeRadius;
        node.setBounds(this.bounds, radius);
      });
    },
    updateSimulation(participants) {
      let newNodes = false;

      this.nodes = participants.map(participant => {
        let { uid, x, y, picture, name } = participant;
        x = this.xScale(x || 0.5);
        y = this.yScale(y || 0.5);

        let node = this.nodesById.get(uid);
        if (!node) {
          node = new ParticipantNode({ uid, x, y });
          this.nodesById.set(uid, node);
          newNodes = true;
        }

        if (node.picture !== picture) {
          node.picture = picture;
          this.imageAtlas.set(node.id, node.picture);
        }

        node.setName(name);
        node.setTarget(x, y);
        return node;
      });

      // Clean up participants that left
      // Array.from(this.nodesById.values())
      //   .filter(n => !this.nodes.includes(n))
      //   .forEach(n => {
      //     console.log("participant left:", n.id);
      //   });

      if (newNodes) {
        this.calculateRadius();
      }

      this.simulation.nodes(this.nodes);
    }
  }
};
</script>
