<template>
  <div class="lobby overlay-gradient">
    <div class="wrapper">
      <p class="intro">
        <strong>Welcome</strong> to Creative Tensions:<br />
        <em>{{ event.name }}.</em>
      </p>
      <p>
        <em>Your host will begin the activity once we’ve reached quorum.</em>
      </p>
      <p class="instruction">
        {{ participants.length }} participants have joined.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["event", "participants"]
};
</script>
