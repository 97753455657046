import { db } from "./firebase";
import { PROMPT } from "./session";

export const PROMPT_TYPES = {
  WARM_UP: "Warm-up",
  REAL_TALK: "Real Talk",
  LIGHTNING: "Lightning"
};
export const PROMPT_DEFAULT_COUNTDOWNS = {
  "Warm-up": 10,
  "Real Talk": 30,
  Lightning: 3
};

export const createPrompt = async ({ eventId, idx = 0 }) => {
  if (!eventId) throw new Error("Must provide an event ID");
  const promptRef = db.ref(`prompts/${eventId}`).push();

  // get the id
  const promptId = promptRef.key;

  const prompt = {
    id: promptId,
    idx,
    prompt: "",
    tension1: "",
    tension2: "",
    type: PROMPT_TYPES.WARM_UP,
    countdown: PROMPT_DEFAULT_COUNTDOWNS[PROMPT_TYPES.WARM_UP]
  };

  let updates = {
    [`events/${eventId}/prompts/${promptId}`]: PROMPT.NOT_STARTED,
    [`prompts/${eventId}/${promptId}`]: prompt
  };

  return db
    .ref()
    .update(updates)
    .then(() => prompt);
};

export const updatePrompt = ({ eventId, prompt }) => {
  if (!eventId) throw new Error("Must provide an event ID");

  const promptRef = db.ref(`prompts/${eventId}/${prompt.id}`);
  return promptRef.update(prompt).then(() => prompt);
};

export const reorderPrompts = ({ eventId, prompts }) => {
  let updates = {};

  prompts = prompts
    .sort((a, b) => a.idx - b.idx)
    .map((p, idx) => {
      p.idx = idx;
      updates[`prompts/${eventId}/${p.id}/idx`] = p.idx;
      return p;
    });

  return db
    .ref()
    .update(updates)
    .then(() => prompts);
};

export const deletePrompt = ({ eventId, prompt }) => {
  let updates = {
    [`events/${eventId}/prompts/${prompt.id}`]: null
  };

  return db
    .ref(`prompts/${eventId}`)
    .transaction(prompts => sortAndRemove(prompts, prompt.id))
    .then(({ snapshot }) => {
      return db
        .ref()
        .update(updates)
        .then(() => snapshot.val());
    });
};

function sortAndRemove(prompts, removedPromptId) {
  return Object.keys(prompts || {})
    .map(id => ({ id, ...prompts[id] }))
    .filter(p => p.id !== removedPromptId)
    .sort((a, b) => a.idx - b.idx)
    .reduce((acc, p, idx) => {
      p.idx = idx;
      acc[p.id] = p;
      return acc;
    }, {});
}
