import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Profile from "../views/Profile.vue";
import Event from "../views/Event.vue";
import AppModal from "../components/shared/AppModal";
import { getErrorMessage } from "../utils/errors";
import { EVENT_ROLES } from "../../functions/lib/constants";
import {
  checkAuth,
  checkAnonymous,
  checkEvent,
  checkEventRole
} from "./guards";
import { logScreenView } from "../api/analytics";

const EventList = () =>
  import(/* webpackChunkName: "edit" */ "../views/EventList.vue");
const EventEdit = () =>
  import(/* webpackChunkName: "edit" */ "../views/EventEdit.vue");
const EventModerate = () =>
  import(/* webpackChunkName: "edit" */ "../views/EventModerate.vue");

Vue.use(VueRouter);

export const ROUTES = {
  HOME: "home",
  PROFILE: "profile",
  EVENT_LIST: "event_list",
  EVENT_EDIT: "event_edit",
  EVENT_MODERATE: "event_moderate",
  EVENT_WATCH: "event_watch",
  EVENT_PARTICIPATE: "event_participate"
};

const routes = [
  {
    path: "/",
    name: ROUTES.HOME,
    component: Home
  },
  {
    path: "/profile",
    name: ROUTES.PROFILE,
    component: Profile
  },
  {
    path: "/events",
    name: ROUTES.EVENT_LIST,
    component: EventList,
    beforeEnter: checkAnonymous,
    meta: { requiresAuth: true }
  },
  {
    path: "/:eventId/edit",
    name: ROUTES.EVENT_EDIT,
    component: EventEdit,
    beforeEnter: checkEventRole([EVENT_ROLES.OWNER]),
    meta: { requiresAuth: true }
  },
  {
    path: "/:eventId/moderate",
    name: ROUTES.EVENT_MODERATE,
    component: EventModerate,
    beforeEnter: checkEventRole([EVENT_ROLES.OWNER, EVENT_ROLES.MODERATOR]),
    meta: { requiresAuth: true }
  },
  {
    path: "/:eventId/watch",
    name: ROUTES.EVENT_WATCH,
    component: Event,
    beforeEnter: checkEventRole([
      EVENT_ROLES.OWNER,
      EVENT_ROLES.MODERATOR,
      EVENT_ROLES.VIEWER
    ]),
    props: { viewOnly: true },
    meta: { requiresAuth: true }
  },
  {
    path: "/:eventId",
    name: ROUTES.EVENT_PARTICIPATE,
    component: Event,
    meta: { requiresAuth: true }
  },
  {
    path: "*",
    redirect: { name: ROUTES.HOME, query: { error: "Location not found." } }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(checkAuth);
router.beforeEach(checkEvent);
router.afterEach(logScreenView);

router.onError(error => {
  if (router.currentRoute.matched.length === 0) {
    router.replace("/");
  }
  router.app.$modal.show(AppModal, getErrorMessage({ message: error.message }));
});
export default router;
