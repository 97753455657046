import { Colors, aspectFit, circle, createCanvasCtx } from "../../utils/canvas";

export default class ImageAtlas {
  constructor(tileSize = 256, width = 2048, height = 2048) {
    this.tileSize = tileSize;
    this.tileIdx = 0;
    this.imageMeta = new Map();
    this.canvas = createCanvasCtx(width, height);
    this.capacity = this.getCapacity();
  }

  getCapacity() {
    const numTilesW = Math.floor(this.canvas.width / this.tileSize);
    const numTilesH = Math.floor(this.canvas.height / this.tileSize);
    return numTilesW * numTilesH;
  }

  getTileRect(idx) {
    const numTilesW = Math.floor(this.canvas.width / this.tileSize);
    const numTilesH = Math.floor(this.canvas.height / this.tileSize);
    const x = (idx % numTilesW) * this.tileSize;
    const y = Math.floor(idx / numTilesH) * this.tileSize;
    return { x, y, w: this.tileSize, h: this.tileSize };
  }

  makeImageTile(ctx, rect, img) {
    const radius = rect.w * 0.5;

    ctx.clearRect(rect.x, rect.y, rect.w, rect.h);
    ctx.save();
    circle(ctx, rect.x + radius, rect.y + radius, radius);
    ctx.clip();

    const fit = aspectFit(img.width, img.height, rect.x, rect.y, rect.w);

    if (img.complete && img.naturalHeight !== 0) {
      ctx.drawImage(img, fit.x, fit.y, fit.w, fit.h);
    } else {
      ctx.fillStyle = Colors.yellowDark;
      ctx.fillRect(fit.x, fit.y, fit.w, fit.h);
    }
    ctx.restore();
  }

  set(id, url) {
    const meta = this.get(id);
    const idx = meta ? meta.idx : this.tileIdx++;
    if (this.tileIdx > this.capacity) {
      this.resize(this.tileSize >> 1);
    }

    let img = new Image();
    // Fix bug with using google profile images that 403 occasionally
    img.referrerPolicy = "no-referrer";
    img.src = url;

    const renderTile = () => {
      const { ctx } = this.canvas;
      const rect = this.getTileRect(idx);
      this.makeImageTile(ctx, rect, img);
      this.imageMeta.set(id, { idx, img, url, ...rect });
    };

    img.onload = renderTile;
    renderTile();
  }

  get(id) {
    return this.imageMeta.get(id);
  }

  resize(tileSize) {
    this.clear();
    this.tileSize = tileSize;
    const { ctx } = this.canvas;

    this.imageMeta.forEach(meta => {
      const rect = this.getTileRect(meta.idx);
      this.makeImageTile(ctx, rect, meta.img);
      Object.assign(meta, rect);
    });

    this.capacity = this.getCapacity();
  }

  clear() {
    const { ctx } = this.canvas;
    ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  drawImage(ctx, id, dx, dy, dw, dh) {
    const meta = this.get(id);
    if (!meta) return;
    ctx.drawImage(this.canvas, meta.x, meta.y, meta.w, meta.h, dx, dy, dw, dh);
  }
}
