import Vue from "vue";
import {
  getUserEventDetails,
  createEvent,
  deleteEvent,
  getEventPrompts,
  updateEvent,
  getEventUsers,
  updateEventUser,
  removeEventUser
} from "../../api/events";
import { normalize } from "../../utils";

export default {
  state: {
    events: {},
    eventIds: [],
    collaborators: {},
    currentEventId: null
  },
  mutations: {
    SET_EVENTS(state, events) {
      state.events = normalize(events);
      state.eventIds = events.map(event => event.id);
    },
    SET_CURRENT_EVENT(state, event) {
      Vue.set(state.events, event.id, event);
      state.currentEventId = event.id;
    },
    ADD_EVENT(state, event) {
      Vue.set(state.events, event.id, event);
      state.eventIds = [...state.eventIds, event.id];
    },
    REMOVE_EVENT(state, eventId) {
      state.eventIds = state.eventIds.filter(id => id !== eventId);
      delete state.events[eventId];
    },
    UPDATE_EVENT(state, event) {
      Vue.set(
        state.events,
        event.id,
        Object.assign({}, state.events[event.id], event)
      );
    },
    SET_COLLABORATORS(state, { event, users }) {
      Vue.set(state.collaborators, event.id, normalize(users, "uid"));
    },
    UPDATE_COLLABORATOR(state, { eventId, user }) {
      Vue.set(state.collaborators[eventId], user.uid, user);
    },
    REMOVE_COLLABORATOR(state, { eventId, user }) {
      Vue.delete(state.collaborators[eventId], user.uid);
    }
  },
  actions: {
    async fetchUserEvents({ commit }, userId) {
      let events = await getUserEventDetails(userId);
      commit("SET_EVENTS", events || []);
      return events;
    },
    async setCurrentEvent({ commit }, event) {
      commit("SET_CURRENT_EVENT", event);
      commit("UPDATE_SESSION", event);
      let prompts = await getEventPrompts(event.id);
      commit("SET_PROMPTS", prompts || {});
    },
    async createEvent({ commit }, payload) {
      const event = await createEvent(payload);
      commit("ADD_EVENT", event);
      return event;
    },
    async removeEvent({ commit, getters }, eventId) {
      const { uid } = getters.user;
      await deleteEvent(uid, eventId);
      commit("REMOVE_EVENT", eventId);
    },
    async updateEvent({ commit }, payload) {
      const event = await updateEvent(payload);
      commit("UPDATE_EVENT", event);
      return event;
    },
    fetchCollaborators({ commit }, event) {
      return getEventUsers(event.id).then(users => {
        commit("SET_COLLABORATORS", { event, users });
        return users;
      });
    },
    updateCollaborator({ commit }, { eventId, email, uid, role }) {
      return updateEventUser({ eventId, email, uid, role }).then(user => {
        commit("UPDATE_COLLABORATOR", { eventId, user });
        return user;
      });
    },
    removeCollaborator({ commit }, { eventId, uid }) {
      return removeEventUser({ eventId, uid }).then(user => {
        commit("REMOVE_COLLABORATOR", { eventId, user });
        return user;
      });
    }
  },
  getters: {
    events: state => state.eventIds.map(id => state.events[id]),
    event: state => state.events[state.currentEventId],
    collaborators: state => state.collaborators[state.currentEventId]
  }
};
