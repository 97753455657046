<template>
  <transition name="fade" mode="out-in" appear>
    <div class="tensions-container wrapper" :key="currentPrompt.id">
      <h3 class="tension left">
        {{ currentPrompt.tension1 }}
      </h3>
      <span class="instruction">
        <!-- Click to position where you stand. -->
        {{ instructions }}
      </span>
      <h3 class="tension right">
        {{ currentPrompt.tension2 }}
      </h3>
    </div>
  </transition>
</template>

<style lang="scss">
.tensions-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  height: calc(2.4rem + 20px);
  line-height: 1.2rem;
  user-select: none;
  padding-top: 10px;
  padding-bottom: 10px;

  .tension {
    margin: 0;
    flex: 1 0;
    max-width: 30%;
    &.left {
      text-align: left;
    }

    &.right {
      text-align: right;
    }
  }
  .instruction {
    padding: 0 20px;
    flex: 1;
  }
}
</style>

<script>
export default {
  props: ["currentPrompt", "viewerType"],
  computed: {
    instructions() {
      if (this.viewerType === "moderator" || this.viewerType === "owner") {
        return "Click on a participant's avatar to call on them, click the board to deselect.";
      } else if (this.viewerType === "participant") {
        return "Click to position your avatar where you stand on this tension.";
      } else if (this.viewerType === "viewer") {
        return "You are in view-only mode.";
      }
      return "";
    }
  }
};
</script>
