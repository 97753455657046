<template>
  <div id="app" :class="currentRouteClass">
    <app-nav :user="user" />
    <router-view v-if="!authLoading" />
    <cookie-banner />
    <app-footer />
    <v-dialog />
    <transition name="fade" mode="out-in">
      <div v-if="authLoading" class="loading-container">
        <app-spinner />
      </div>
    </transition>
  </div>
</template>

<style lang="scss">
@import "./styles/main";

.loading-container {
  background: black;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import AppNav from "./components/app/AppNav";
import AppFooter from "./components/app/AppFooter";
import AppSpinner from "./components/shared/AppSpinner";
import AppModal from "./components/shared/AppModal";
import CookieBanner from "./components/app/CookieBanner";
import { ROUTES } from "./router";
import { fetchAuthState } from "./api/auth";
import { getErrorMessage } from "./utils/errors";

export default {
  components: {
    AppNav,
    AppFooter,
    AppSpinner,
    CookieBanner
  },
  computed: {
    ...mapGetters(["authLoading", "user"]),
    currentRouteClass() {
      const { name } = this.$route;
      if (
        name === null ||
        name === ROUTES.EVENT_MODERATE ||
        name === ROUTES.EVENT_PARTICIPATE ||
        name === ROUTES.EVENT_WATCH
      ) {
        return "fixed";
      }
      return "";
    }
  },
  async mounted() {
    try {
      let { user, ...result } = await fetchAuthState();
      const { redirect, error } = this.$route.query;

      if (error) {
        throw new Error(error);
      }

      await this.setUser(user);

      let promise = Promise.resolve();
      if (user && result.operationType === "signIn") {
        if (redirect) {
          promise = this.$router.replace(redirect);
        } else if (!user.isAnonymous) {
          promise = this.$router.replace("/events");
        }
      }

      promise.then(() => {
        this.setAuthLoading(false);
      });
    } catch (err) {
      this.handleError(err);
      this.setAuthLoading(false);
    }
  },
  errorCaptured(err) {
    this.handleError(err);
  },
  methods: {
    ...mapActions(["setAuthLoading", "setUser"]),
    handleError(err) {
      this.$modal.show(AppModal, getErrorMessage(err));
    }
  }
};
</script>
