<template>
  <div class="prompt-countdown-container">
    <transition name="countdown" appear>
      <div
        v-if="duration > 0"
        :key="time"
        class="box"
        :style="countdownStyle"
        @animationend="onComplete"
      ></div>
    </transition>
  </div>
</template>
<style lang="scss">
@import "../styles/variables";
.prompt-countdown-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  .box {
    height: 8px;
    width: 100%;
    transform-origin: center center;
    background-color: $primary;
    transform: scaleX(0);
  }

  .countdown-enter-active {
    animation: slidein 1s linear 0s 1;
  }

  @keyframes slidein {
    0% {
      transform: scaleX(1);
      opacity: 1;
    }
    90% {
      transform: scaleX(0.1);
      opacity: 1;
    }
    100% {
      transform: scaleX(0);
      opacity: 0;
    }
  }
}
</style>
<script>
import { timestamp } from "../utils";
export default {
  props: ["time"],
  computed: {
    duration() {
      if (this.time) {
        return Math.max(this.time - timestamp(), 0);
      }
      return 0;
    },
    countdownStyle() {
      return {
        animationDuration: `${this.duration}ms`
      };
    }
  },
  methods: {
    onComplete() {
      this.$emit("complete");
    }
  }
};
</script>
