<template>
  <div class="backgroundCanvasContainer" ref="container" v-on="$listeners">
    <canvas ref="canvas" />
  </div>
</template>

<style lang="scss">
.backgroundCanvasContainer,
.backgroundCanvasContainer canvas {
  width: 100%;
  height: 100%;
}
</style>

<script>
import { Colors, circle } from "../utils/canvas.js";

export default {
  props: ["nodes"],
  mounted() {
    this.container = this.$refs.container;
    this.canvas = this.$refs.canvas;
    this.context = this.canvas.getContext("2d");
    window.addEventListener("resize", this.rescale);
    this.rescale();
    this.animate();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.rescale);
    if (this.animationReq) {
      window.cancelAnimationFrame(this.animationReq);
    }
  },
  methods: {
    animate() {
      this.draw();
      this.animationReq = window.requestAnimationFrame(this.animate);
    },
    draw() {
      const ctx = this.context;

      // Clear
      ctx.clearRect(0, 0, this.bounds.width, this.bounds.height);

      /// Draw Nodes
      for (let i = 1, numNodes = this.nodes.length; i < numNodes; i++) {
        const node = this.nodes[i];
        /// Outline
        circle(ctx, node.x, node.y, node.radius);
        ctx.strokeStyle = Colors.white;
        ctx.lineWidth = 2.0;
        ctx.stroke();
      }
    },
    rescale() {
      this.bounds = this.$refs.container.getBoundingClientRect();

      var devicePixelRatio = window.devicePixelRatio || 1;
      var backingStorePixelRatio =
        this.context.webkitBackingStorePixelRatio ||
        this.context.mozBackingStorePixelRatio ||
        this.context.msBackingStorePixelRatio ||
        this.context.oBackingStorePixelRatio ||
        this.context.backingStorePixelRatio ||
        1;

      var pixelRatio = devicePixelRatio / backingStorePixelRatio;

      this.canvas.width = this.bounds.width * pixelRatio;
      this.canvas.height = this.bounds.height * pixelRatio;
      this.context.scale(pixelRatio, pixelRatio);
    }
  }
};
</script>
