import { db, firebase } from "./firebase";
import { merge, timestamp } from "../utils";

const { TIMESTAMP } = firebase.database.ServerValue;

// State variables
// session: not-started, in-progress, concluded
// prompt: not-started, collecting-input (only your node visible), active (all nodes visible), concluded
export const PROMPT = {
  NOT_STARTED: "not-started",
  SHOW_INDIVIDUAL: "individual",
  SHOW_ALL: "all",
  CLOSED: "closed"
};

export const EVENT = {
  INITIAL: "initial",
  INTERSTITIAL: "interstitial",
  ACTIVE: "active",
  FINISHED: "finished"
};

export default class Session {
  constructor(eventId) {
    this.eventId = eventId;
    this.eventRef = db.ref(`events/${eventId}`);
    this.sessionRef = db.ref(`events/${eventId}`);
    this.participantsRef = db.ref(`participants/${eventId}`);
    this.promptsRef = db.ref(`prompts/${eventId}`);
  }

  join(user, removeOnDisconnect = true) {
    const { uid, name, picture } = user;
    this.userRef = this.participantsRef.child(uid);

    this.userRef.update({
      name,
      picture
    });

    if (removeOnDisconnect) {
      this.userRef.onDisconnect().remove();
    }
  }

  async leave() {
    if (this.userRef) {
      await this.userRef.remove();
      await this.userRef.onDisconnect().cancel();
    }
    this.unsubscribe();
  }

  unsubscribe() {
    this.participantsRef.off();
    this.sessionRef.off();
  }

  updatePosition({ x, y }) {
    if (!this.userRef) return;
    this.userRef.update({ x, y });
  }

  reset() {
    this.sessionRef.transaction(session => {
      session.prompts = Object.keys(session.prompts).reduce((acc, id) => {
        acc[id] = PROMPT.SHOW_INDIVIDUAL;
        return acc;
      }, {});

      session.state = EVENT.INITIAL;
      session.currentPrompt = 0;
      session.currentSpeaker = null;
      session.raisedHands = null;
      session.countdown = null;
      session.updatedAt = TIMESTAMP;

      return session;
    });
  }

  start() {
    this.sessionRef.transaction(session => {
      session.state = EVENT.INTERSTITIAL;
      session.currentPrompt = 0;
      session.updatedAt = TIMESTAMP;
      return session;
    });
  }

  revealPrompt() {
    this.fetchPrompts().then(prompts => {
      this.sessionRef.transaction(session => {
        const promptIds = Object.keys(session.prompts);
        const promptIdx = session.currentPrompt;
        const curPromptId = promptIds[promptIdx];
        session.countdown = timestamp(prompts[curPromptId].countdown);
        session.prompts[curPromptId] = PROMPT.SHOW_INDIVIDUAL;
        session.state = EVENT.ACTIVE;
        session.updatedAt = TIMESTAMP;
        return session;
      });
    });
  }

  nextPrompt() {
    this.fetchPrompts().then(prompts => {
      this.sessionRef.transaction(session => {
        const promptIds = Object.keys(session.prompts);
        const promptIdx = session.currentPrompt;
        const curPromptId = promptIds[promptIdx];
        const curType = prompts[curPromptId].type;

        if (session.currentPrompt === null) {
          session.currentPrompt = 0;
        } else if (promptIdx < promptIds.length - 1) {
          session.prompts[curPromptId] = PROMPT.CLOSED;
          session.currentPrompt = promptIdx + 1;

          const nextPromptId = promptIds[promptIdx + 1];
          if (prompts[nextPromptId].type != curType) {
            session.state = EVENT.INTERSTITIAL;
            session.countdown = null;
          } else {
            if (session.prompts[nextPromptId] !== PROMPT.CLOSED) {
              session.prompts[nextPromptId] = PROMPT.SHOW_INDIVIDUAL;
              session.countdown = timestamp(prompts[nextPromptId].countdown);
            } else {
              session.countdown = null;
            }
          }
        }

        session.updatedAt = TIMESTAMP;
        session.raisedHands = null;
        session.speaking = null;

        return session;
      });
    });
  }

  prevPrompt() {
    this.sessionRef.transaction(session => {
      if (session.currentPrompt === null) {
        session.currentPrompt = 0;
      } else if (session.currentPrompt > 0) {
        if (session.state === EVENT.FINISHED) {
          session.state = EVENT.ACTIVE;
        } else {
          session.currentPrompt -= 1;
        }
      }

      session.updatedAt = TIMESTAMP;
      session.raisedHands = null;
      session.speaking = null;
      session.countdown = null;

      return session;
    });
  }

  setState(state) {
    this.sessionRef.transaction(session => {
      session.state = state;
      session.updatedAt = TIMESTAMP;

      if (state === EVENT.FINISHED) {
        const promptIds = Object.keys(session.prompts);
        const promptIdx = session.currentPrompt;
        const curPromptId = promptIds[promptIdx];
        session.prompts[curPromptId] = PROMPT.CLOSED;
        session.countdown = null;
        session.finishedAt = TIMESTAMP;
      }

      return session;
    });
  }

  setPromptState(promptId, state) {
    this.sessionRef.transaction(session => {
      if (session.prompts[promptId] !== undefined) {
        session.prompts[promptId] = state;
        if (state != PROMPT.SHOW_INDIVIDUAL) session.countdown = null;
      }
      return session;
    });
  }

  setSpeaking(participant) {
    this.sessionRef.transaction(session => {
      session.speaking = participant ? participant.id : null;
      if (participant) {
        session.raisedHands = merge(session.raisedHands, participant.id, null);
      }
      return session;
    });
  }

  setHandRaised(participantId, value) {
    return this.sessionRef
      .child(`raisedHands/${participantId}`)
      .set(value || null);
  }

  onSessionChanged(cb) {
    return this.addListener(this.sessionRef, "value", cb);
  }

  onParticipantAdded(cb) {
    return this.addListener(this.participantsRef, "child_added", cb);
  }

  onParticipantRemoved(cb) {
    return this.addListener(this.participantsRef, "child_removed", cb);
  }

  onParticipantChanged(cb) {
    return this.addListener(this.participantsRef, "child_changed", cb);
  }

  addListener(ref, eventType, cb) {
    ref.on(eventType, snap => {
      const val = snap.val();
      if (val !== null && typeof val === "object") {
        val.id = snap.key;
      }
      cb(val);
    });
  }

  fetchPrompts() {
    return this.promptsRef.once("value").then(snap => snap.val());
  }
}
