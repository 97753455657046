<template>
  <transition name="fade">
    <div class="overlay-container" v-if="overlayComponent">
      <transition name="fade" mode="out-in" appear>
        <component
          :is="overlayComponent"
          :event="event"
          :participants="participants"
          :currentPrompt="currentPrompt"
        ></component>
      </transition>
    </div>
  </transition>
</template>
<style lang="scss">
.overlay-container {
  .overlay-gradient {
    position: relative;
    background: linear-gradient(rgba(0, 0, 0, 0.9) 50%, transparent);
    z-index: 2;
  }
  .wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .instruction {
    font-size: 1rem;
  }
  a {
    pointer-events: all;
  }
}
</style>
<script>
import { EVENT } from "../api/session";
import Lobby from "../components/Lobby";
import Interstitial from "../components/Interstitial";
import Finished from "../components/Finished";

export default {
  props: ["state", "event", "participants", "currentPrompt"],

  computed: {
    overlayComponent() {
      const componentMap = {
        [EVENT.INITIAL]: Lobby,
        [EVENT.INTERSTITIAL]: Interstitial,
        [EVENT.FINISHED]: Finished
      };
      return componentMap[this.state];
    }
  }
};
</script>
