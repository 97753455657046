import firebase from "firebase/app";
import { auth } from "./firebase";
import apiClient from "./client";

export const getUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((user, err) => {
      unsubscribe();
      if (err) {
        return reject(err);
      }
      resolve(user);
    });
  });
};

export function getIdToken() {
  return auth.currentUser.getIdToken();
}

export function signInAnonymously() {
  return auth.signInAnonymously();
}

export function unlink(providerId) {
  return auth.currentUser.unlink(providerId);
}

export function signOut() {
  return auth.signOut();
}

export async function signInWithGoogle(options) {
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.setCustomParameters(options);

  await auth.signInWithRedirect(provider);
}

export async function verifyUser() {
  try {
    const res = await apiClient.post("/auth/verify");
    await auth.currentUser.getIdToken(true);
    return res.data;
  } catch (err) {
    await auth.signOut();
    throw err;
  }
}

export async function fetchAuthState() {
  let result = await auth.getRedirectResult();
  if (result.operationType === "signIn") {
    await verifyUser();
  } else {
    let user = await getUser();
    result = Object.assign({}, result, { user });
  }
  return result;
}

/* Authentication logic is handled in App.vue
export const authPlugin = store => {
  auth.onAuthStateChanged(async user => {
    store.dispatch("setUser", user);
  });
};
*/
