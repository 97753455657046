export const Colors = {
  line: "rgba(196, 196, 196, 1)",
  yellow: "rgba(251, 240, 83, 1)",
  yellowAlpha: a => `rgba(251, 240, 83, ${a})`,
  yellowDark: "#4B4819",
  white: "rgba(255, 255, 255, 0.6)"
};

const TWO_PI = 2 * Math.PI;

export function createCanvasCtx(w, h) {
  let canvas = document.createElement("canvas");
  canvas.width = w;
  canvas.height = h;
  canvas.ctx = canvas.getContext("2d");
  return canvas;
}

export function oval(context, x, y, w, h) {
  context.save();
  context.beginPath();
  context.translate(x, y);
  context.scale(w * 0.5, h * 0.5);
  context.arc(1, 1, 1, 0, TWO_PI, false);
  context.closePath();
  context.restore();
}

export function circle(context, x, y, r) {
  context.beginPath();
  context.arc(x, y, r, 0, TWO_PI, false);
  context.closePath();
}

export function makeRect(x, y, w, h) {
  return { x: x, y: y, w: w, h: h };
}

export function aspectFit(width, height, x, y, d) {
  if (width == height) {
    return makeRect(x, y, d, d);
  }
  const long = d * (Math.max(width, height) / Math.min(width, height));
  return width < height
    ? makeRect(x, y - (long - d) * 0.5, d, long)
    : makeRect(x - (long - d) * 0.5, y, long, d);
}
