import { getUser } from "../api/auth";
import { getEvent, hasEventRole } from "../api/events";
import { ROUTES } from ".";
import store from "../store";

export async function checkAuth(to, from, next) {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const user = await getUser();

    if (user) {
      return next();
    }

    return next({
      path: "/",
      query: { redirect: to.fullPath }
    });
  } else {
    next();
  }
}

export async function checkAnonymous(to, from, next) {
  const user = await getUser();
  if (!user || user.isAnonymous) {
    return next(new Error("You do not have permissions to create events."));
  }

  return next();
}

export async function checkEvent(to, from, next) {
  const { eventId } = to.params;
  if (eventId === undefined) return next();

  const event = await getEvent(eventId);

  if (event) {
    store.dispatch("setCurrentEvent", event);
    next();
  } else {
    next(new Error(`Event "${eventId}" does not exist.`));
  }
}

export const checkEventRole = (roles = []) => async (to, from, next) => {
  const { user, event } = store.getters;

  if (hasEventRole(user, event, roles)) {
    next();
  } else {
    let eventName = event ? `"${event.name}."` : "this event.";
    const actionsMap = {
      [ROUTES.EVENT_EDIT]: "edit",
      [ROUTES.EVENT_MODERATE]: "moderate",
      [ROUTES.EVENT_WATCH]: "observe"
    };
    const action = actionsMap[to.name] || "access";
    next(new Error(`You do not have permissions to ${action} ${eventName}`));
  }
};
