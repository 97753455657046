const chroma = require("chroma-js/src/chroma");

// feel free to comment out anything to rollup
// a smaller chroma.js built

// io --> convert colors
// require("chroma-js/src/io/css");
require("chroma-js/src/io/hex");
require("chroma-js/src/io/lch");
// require("chroma-js/src/io/hsl");
// require("chroma-js/src/io/lab");
// require("chroma-js/src/io/rgb");

// operators --> modify existing Colors
// require("chroma-js/src/ops/alpha");
// require("chroma-js/src/ops/darken");
// require("chroma-js/src/ops/get");
require("chroma-js/src/ops/mix");
// require("chroma-js/src/ops/set");

// interpolators
require("chroma-js/src/interpolator/lch");

// generators -- > create new colors
chroma.mix = chroma.interpolate = require("chroma-js/src/generator/mix");

// other utility methods
chroma.valid = require("chroma-js/src/utils/valid");

module.exports = chroma;
