<template>
  <div class="canvas-container" ref="container">
    <canvas
      ref="canvas"
      @mousemove="hoverBoard($event)"
      @click="clickBoard($event)"
    />
    <participant
      :participant="currentSpeaker"
      :speaker="speaker"
      :user="user"
    />
    <participant :participant="hoverNode" :speaker="speaker" :user="user" />
  </div>
</template>

<style lang="scss">
.canvas-container {
  position: relative;
  flex: 1 1 auto;
  display: flex;

  canvas {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 100%;
    max-height: 100%;
    z-index: 2;
  }
}
</style>

<script>
import Participant from "./Participant";
import { Colors, circle } from "../utils/canvas";

const NodeState = {
  none: 0,
  speaker: 100,
  handRaised: 101,
  me: 102
};

export default {
  props: ["nodes", "imageAtlas", "speaker", "raisedHands", "user"],
  components: {
    Participant
  },
  computed: {
    currentSpeaker() {
      return this.nodes.filter(node => node.id === this.speaker)[0] || null;
    }
  },
  data() {
    return {
      hoverNode: null
    };
  },
  mounted() {
    this.canvas = this.$refs.canvas;
    this.context = this.canvas.getContext("2d");
    window.addEventListener("resize", this.rescale);
    this.rescale();
    this.animate();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.rescale);
    if (this.animationReq) {
      window.cancelAnimationFrame(this.animationReq);
    }
  },
  methods: {
    nodeFor(event) {
      const x = event.offsetX;
      const y = event.offsetY;
      const hit = this.nodes.filter(node => {
        return (
          x > node.x - node.radius &&
          x < node.x + node.radius &&
          y > node.y - node.radius &&
          y < node.y + node.radius
        );
      });
      return hit.length > 0 ? hit[0] : null;
    },
    hoverBoard(event) {
      this.hoverNode = this.nodeFor(event);
    },
    clickBoard(event) {
      this.hoverBoard(event);
      this.$emit("participantClicked", this.hoverNode, event);
    },
    animate(time) {
      this.draw(time);
      this.animationReq = window.requestAnimationFrame(this.animate);
    },
    draw(time) {
      const ctx = this.context;

      // Clear
      ctx.clearRect(0, 0, this.bounds.width, this.bounds.height);

      /// Draw Nodes
      for (let i = 0, numNodes = this.nodes.length; i < numNodes; i++) {
        const node = this.nodes[i];

        const nodeX = Math.round(node.x);
        const nodeY = Math.round(node.y);
        let radius = Math.round(node.radius);

        this.imageAtlas.drawImage(
          ctx,
          node.id,
          nodeX - radius,
          nodeY - radius,
          radius * 2,
          radius * 2
        );

        /// Outline
        const state =
          node.id === this.speaker
            ? NodeState.speaker
            : this.raisedHands[node.id] !== undefined
            ? NodeState.handRaised
            : node.id === this.user.uid
            ? NodeState.me
            : NodeState.none;

        if (state === NodeState.none) {
          continue;
        }

        if (state === NodeState.handRaised) {
          const phase = Math.pow((time % 2000.0) / 2000.0, 2);
          radius *= 1.0 + phase * 0.5;
          ctx.strokeStyle = Colors.yellowAlpha(1.0 - phase);
          ctx.lineWidth = phase * 10.0 + 3.0;
        } else {
          ctx.strokeStyle = Colors.yellow;
          ctx.lineWidth = 3.0;
        }

        circle(ctx, nodeX, nodeY, radius + 6);
        ctx.save();
        if (state === NodeState.speaker) {
          ctx.lineCap = "round";
          ctx.lineJoin = "round";
          ctx.setLineDash([3, 10]);
          ctx.lineDashOffset = time / 100.0;
        }
        ctx.stroke();
        ctx.restore();
      }
    },
    rescale() {
      this.bounds = this.$refs.container.getBoundingClientRect();

      var devicePixelRatio = window.devicePixelRatio || 1;
      var backingStorePixelRatio =
        this.context.webkitBackingStorePixelRatio ||
        this.context.mozBackingStorePixelRatio ||
        this.context.msBackingStorePixelRatio ||
        this.context.oBackingStorePixelRatio ||
        this.context.backingStorePixelRatio ||
        1;

      var pixelRatio = devicePixelRatio / backingStorePixelRatio;

      this.canvas.width = this.bounds.width * pixelRatio;
      this.canvas.height = this.bounds.height * pixelRatio;
      this.context.scale(pixelRatio, pixelRatio);
    }
  }
};
</script>
