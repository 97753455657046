<template>
  <div class="login">
    <p class="login-instruction">
      Sign in with your IDEO email
      <span v-if="showCreateProfile">or create a temporary profile</span>
      to continue.
    </p>
    <div>
      <button class="google-login" @click="signInWithGoogle()">
        Sign in with Google
      </button>
    </div>

    <div v-if="showCreateProfile">
      <p><em>- or -</em></p>
      <button class="primary" @click="createProfile()">
        Create Temporary Profile
      </button>
    </div>
    <div class="terms">
      By continuing, you agree to our<br />
      <a href="#" @click.prevent="showTerms()">Terms,</a>
      <a href="https://www.ideo.com/privacy" target="_blank">
        Privacy Policy and Cookies Policy.
      </a>
    </div>
  </div>
</template>
<style lang="scss">
@import "../../styles/variables";

.login {
  text-align: center;

  .login-instruction {
    font-family: $san-serif;
    font-size: 0.9rem;
    max-width: 48ch;
    margin: 2rem auto 2rem;
  }

  .google-login {
    background: #fff url(../../assets/google-logo.svg) no-repeat;
    background-size: 20px;
    background-position: 15px center;
    padding-left: 60px;
    border: none;
    border-radius: 3px;
    text-transform: none;
    letter-spacing: initial;
    color: $grey-dark;

    &:hover {
      opacity: 0.9;
    }
  }

  .terms {
    font-style: italic;
    font-weight: 200;
    color: $grey;
    font-size: 0.8rem;
    line-height: 1.3rem;
    margin: 2rem 0 3.6rem;
    a {
      color: $copy;
      font-weight: 700;
      text-decoration: none;

      &:hover {
        color: $primary;
        text-decoration: underline;
      }
    }
  }
}
</style>
<script>
import { signInWithGoogle } from "../../api/auth";
const TermsModal = () => import(/* webpackChunkName: "terms" */ "./TermsModal");

import { mapActions } from "vuex";

export default {
  computed: {
    showCreateProfile() {
      const { redirect } = this.$route.query;
      return redirect;
    }
  },
  methods: {
    ...mapActions(["setAuthLoading"]),
    signInWithGoogle() {
      this.setAuthLoading(true);
      signInWithGoogle({
        hd: "*",
        prompt: "select_account"
      });
    },
    createProfile() {
      this.$router.push({
        path: "/profile",
        query: {
          ...this.$route.query
        }
      });
    },
    showTerms() {
      this.$modal.show(
        TermsModal,
        {},
        { width: 1300, height: 600, maxHeight: 600, maxWidth: 1300 }
      );
    }
  }
};
</script>
